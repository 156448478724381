.testi-container {
  width: 100%;
  height: 55rem;
  background: linear-gradient(
    to bottom,
    rgba(207, 233, 207, 0),
    rgba(207, 233, 207, 0.1),
    rgba(207, 233, 207, 0.9)
  );
}

#carouselExampleControls {
  width: 75%;
  height: 32%;
}
.carousel-item {
  width: 100%;
  height: 100%;
}
.carousel-testimony {
  width: 75%;
  height: 100%;
}

.container .carousel-button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.testi-text {
  font-size: 24px;
}

@media only screen and (max-width: 998px) {
  .testi-container {
    height: auto !important;
  }
  .testimony-crousel-content {
    margin-top: 5rem !important;
  }
  .people-opinion-asso {
    font-size: 50px !important;
  }
  #associations {
    height: 44rem !important;
  }
  .asso-header {
    font-size: 50px;
  }
  #carouselExampleControls {
    width: 100%;
  }
  .carousel-testimony {
    width: 80%;
  }
}
@media only screen and (max-width: 576px) {
  .asso-header {
    font-size: 39px !important;
  }
  .people-opinion-asso {
    font-size: 39px !important;
  }
}
