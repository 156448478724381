.card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  background-color: #cfe9cf;
  width: 100%;
  height: 400px;
  padding: 0 2rem 0 2rem;
  border-radius: 10px;
}

.card>img{
  margin-top: -20%;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 3px 1px black;
}

.card h2 {
  margin: 0;
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
}

.card p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
}

/* .card .btnn {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.cardTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}
.cardTitle .imgSize {
  width: 25px;
}
