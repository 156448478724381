.stepper-img-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: thin solid #00aa13;
  box-shadow: 0 0 2px 1px #00aa13;
}

.stepper-img {
  width: 100%;
  aspect-ratio: 2/1.5;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.stepper-content-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.stepper-content-text-container .h1 {
  text-align: left;
}

.stepper-content-text-container .h5 {
  text-align: justify;
}
.stepper-parent-container {
  position: relative;
}
.stepper-container {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  height: 100px;
  scroll-snap-stop: always;
}
.stepper-container::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}
.stepper-arrow {
  display: none;
}
.milestone-container {
  height: 90vh;
}
.abt-stepper-content {
  height: 20rem !important;
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 1400px) {
  /* .stepper-img {
    max-width: 300px;
  } */
  .stepper-arrow {
    display: block;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 776px) {
  .milestone-container {
    height: 100vh;
  }
  .abt-stepper-content {
    height: 38rem !important;
  }
  .stepper-img {
    aspect-ratio: 2/1;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  /* .stepper-img {
    max-width: 300px;

  } */
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  /* .stepper-img {
    max-width: 300px;
  } */
}

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
  /* .stepper-img {
    max-width: 300px;
  } */
}

/* XX-Large devices (larger desktops) */
/* No media query since the xxl breakpoint has no upper bound on its width */

@media (max-width: 576px) {
}
