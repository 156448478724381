.gt-new-app-container {
  width: 100%;
  height: calc(100vh - 90px);
  /* background-image: url("https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/background/2.svg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.gt-app-left-part {
  height: 100%;
  width: 50%;
}
.gt-para-first-word {
  font-size: 65px;
}
.gt-logo-app-gt img {
  width: 160px !important;
  height: 160px !important;
  object-fit: cover;
  object-position: center;
}

/* .gt-head-app {
  left: 56.5%;
  top: 18%;
}
.gt-logo-app-gt {
  left: 65% !important;
  top: 57%;
}

.gt-app-right-part {
  width: 30%;
  border: 2px solid red;
} */
.gt-app-right-part {
  width: 40%;
  gap: 3.5rem;
}
.bg-active {
  opacity: 1 !important;
  transition: all 0.5 ease-in-out;
}
@media only screen and (max-width: 1277px) {
  .gt-new-app-container {
    height: calc(100vh - 105px);
  }
  .gt-app-left-part {
    height: 100%;
    width: 100%;
    background-size: contain !important;
  }
  .gt-new-app-container {
    flex-direction: column-reverse;
    align-items: center;
  }
  .gt-app-right-part {
    position: absolute;
    top: 5%;
    left: 0%;
    z-index: 2;
    align-items: flex-start;
    margin-left: 5%;
    gap: 0.5rem;
  }
  .gt-para-first-word {
    font-size: 40px;
  }
  .gt-app-right-part {
    width: auto;
    align-items: center;
  }
  .type-gt-anima > p {
    font-size: 25px !important;
  }
  .gt-logo-app-gt img {
    width: 100px !important;
    height: 100px !important;
  }
  .gt-logo-app-gt {
    height: 50px !important;
  }
  .type-gt-anima {
    height: 62px !important;
  }
}
@media only screen and (max-width: 938px) {
  .gt-app-left-part {
    height: 80%;
    width: 80%;
  }
  .gt-para-first-word {
    font-size: 30px;
  }
  .type-gt-anima > p {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 774px) {
  .gt-app-left-part {
    height: 40%;
    width: 80%;
  }
  .gt-app-right-part {
    width: 100%;
    align-items: center;
    margin-left: 0;
    gap: 1rem !important;
    top: 4% !important;
  }
  .gt-para-first-word {
    font-size: 50px;
  }
  .type-gt-anima > p {
    font-size: 38px !important;
  }
  .type-gt-anima {
    height: 54px !important;
  }
  .gt-logo-app-gt img {
    width: 110px !important;
    height: 110x !important;
  }
}
@media only screen and (max-width: 576px) {
  .gt-app-left-part {
    width: 100%;
    height: 50%;
  }
  .gt-logo-app-gt img {
    width: 180px !important;
    height: 120px !important;
  }
  .type-gt-anima {
    height: 110px !important;
  }

  .gt-para-first-word {
    font-size: 40px;
  }
}
