.leaf-sec-container {
  height: calc(100vh - 90px - 3rem);
  width: 100%;
  background-image: url("https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/grass/46.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.circular-div {
  height: 110%;
  width: 55%;
  border-radius: 50%;
  position: absolute;
  right: -21%;
  top: -5%;
  background-color: #cfe9cf;
  opacity: 0.75;
  z-index: 2;
}

.circular-div-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 80%;
  margin: 5% 0%;
}

.grass-text {
  width: 70%;
  height: 10vw;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 3;
  -webkit-transition: background-image 1s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.para-container {
  height: 10vw;
  display: flex;
  align-items: center;
  position: absolute;
  right: 3%;
  top: 38%;
  z-index: 3;
}

span.grass-letter {
  font-size: 6rem;
  transition: transform 1s, font-size 0.5s ease-in-out, color 0.5s ease-in-out;
}

span.grass-letter.active {
  font-size: 10rem;
  color: #00aa13;
}

.circular-para {
  font-size: 24px;
  line-height: 2.5rem;
  text-align: center;
  width: 20vw;
  white-space: break-spaces;
}
.circular-card-1 {
  position: absolute;
  top: 3%;
  right: 21.5%;
  width: 105px;
  height: 105px;
  transition: transform 1s, box-shadow 1s ease-in-out;
  border-radius: 50%;
  z-index: 5;
}
.circular-card-2 {
  position: absolute;
  top: 20%;
  right: 27.5%;
  width: 105px;
  height: 105px;
  transition: transform 1s, box-shadow 1s ease-in-out;
  border-radius: 50%;
  z-index: 5;
}
.circular-card-3 {
  position: absolute;
  top: 44%;
  right: 30.5%;
  width: 105px;
  height: 105px;
  transition: transform 1s, box-shadow 1s ease-in-out;
  border-radius: 50%;
  z-index: 5;
}
.circular-card-4 {
  position: absolute;
  bottom: 20%;
  right: 27.5%;
  width: 105px;
  height: 105px;
  transition: transform 1s, box-shadow 1s ease-in-out;
  border-radius: 50%;
  z-index: 5;
}
.circular-card-5 {
  position: absolute;
  bottom: 3%;
  right: 21.5%;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  transition: transform 1s, box-shadow 1s ease-in-out;
  z-index: 5;
}
.leaf-in-hand-active {
  border-radius: 50%;
  box-shadow: 0 0 8px 4px #00aa13;
  transition: transform 1s, box-shadow 1s ease-in-out;

  transform: scale(1.2);
}

.grass-container {
  position: absolute;
  width: 66%;
}
/* .leaf-description-text {
  top: 21%;
  left: 80%;
} */
.leaf-title {
  top: -13%;
  left: 84%;
}
/* @media only screen and (max-width: 998px) {
 
  .circular-card-1 {
    top: 29%;
    left: 1%;
  }
  .circular-card-2 {
    top: 16%;
    right: 66.5%;
  }
  .circular-card-4 {
    bottom: 68%;
    right: 19.5%;
  }
  .circular-card-3 {
    top: 13%;
    right: 43%;
  }
  .circular-card-5 {
    top: 30%;
    right: 1%;
  }
  .circular-para {
    width: 35%;
    top: 55%;
    right: 30%;
    text-align: center;
  }
} */
/* @media only screen and (max-width: 768px) {
  .leaf-sec-container {
    background-image: url("https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/grass/leaf-mobile-removebg-preview.png");
  }

  .circular-div {
    height: 35%;
    width: 110%;

    top: 65%;
    right: -5%;
  }
  .circular-para {
    width: 45%;
    top: 55%;
    right: 30%;
    font-size: 16px;
  }
  .next-btn h4 {
    font-size: 18px;
  }
  .circular-card-1 {
    top: 64%;
    left: 1%;
  }

  .circular-card-2 {
    top: 57%;
    left: 21%;
  }

  .circular-card-3 {
    top: 57%;
    left: 43%;
  }

  .circular-card-4 {
    top: 58%;
    right: 21%;
  }

  .circular-card-5 {
    top: 63%;
    right: 1%;
  }
  .circular-card {
    width: calc(100vw / 7);
    height: calc(100vw / 7);
    aspect-ratio: 1;
  }
}
@media only screen and (max-width: 576px) {
  .circular-card {
    width: calc(100vw / 6);
    height: calc(100vw / 6);
    aspect-ratio: 1;
  }
  .leaf-sec-container {
    background-image: url("https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/grass/leaf-mobile-removebg-preview.png");
  }

  .circular-card-1 {
    top: 70%;
    left: 0%;
  }

  .circular-card-2 {
    top: 63%;
    left: 18%;
  }

  .circular-card-3 {
    top: 60%;
    left: 42%;
  }

  .circular-card-4 {
    top: 63%;
    right: 18%;
  }

  .circular-card-5 {
    top: 70%;
    right: 0%;
  }

  .leaf-sec-container .circular-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  .grass-container .grass-text {
    position: static;
    height: 40vw;
    width: 55vw;
  }
  .leaf-sec-container .circular-div .circular-para {
    position: static;
    width: 80%;
    margin: auto;
  }
  .leaf-sec-container .circular-div .para-container {
    height: 25%;
    width: 90%;
    display: flex;
  }
} */
