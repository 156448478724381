nav {
  font-size: 10px;
  position: relative;
  height: 90px;
}

nav button {
  transform: scale(1.2);
}

.nav-right-side {
  width: 45em;
  flex-shrink: 0;
  gap: 20px;
  justify-content: flex-end;
  margin-right: 40px;
}

.logo {
  background-image: url("https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/logo.png");
  width: 18em;
  height: 5.5em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 75px;
  transform: scale(1.4);
}
.hamburger {
  font-size: 4.5em;
  color: #00aa13;
  cursor: pointer;
}
.hamburger:hover {
  color: #03520c;
}
.data-btn {
  background-color: #00aa13;
  border: none;
  font-size: 1.5em;
}
.nav-container {
  position: fixed;
  top: 0;
  z-index: 999999;
  width: 100%;
}
@media only screen and (max-width: 992px) {
  .nav-our-product-btn {
    display: none !important;
  }
  .nav-right-side {
    width: 21em;
    gap: 9px;
    margin-right: 19px;
  }
}

@media only screen and (max-width: 711px) {
  /* .nav-right-side {
    width: 30em;
    gap: 9px;
    margin-right: 19px;
  } */
  .logo {
    width: 14em;
    margin-left: 42px;
  }
}
@media only screen and (max-width: 576px) {
  .nav-custom-btn {
    font-size: 1.2em;
    width: 64px;
    padding: 0px !important;
  }

  /* .nav-right-side {
    width: 12em;
    margin-right: 5px;
  } */

  .nav-our-product-btn {
    display: none;
  }
  .logo {
    width: 12em;
    margin-left: 26px;
  }
}
@media only screen and (max-width: 430px) {
  .nav-right-side {
    width: 16.5em;
    margin-right: 10px;
  }
  .book-btn {
    font-size: 10px !important;
    width: 95px !important;
    font-weight: bold !important;
  }
}

.nav-footer {
  /* background-image: url("https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/background/2.svg"); */
  background-color: rgba(0, 170, 19, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  height: 3rem;
  align-items: center;
}

.nav-footer p {
  transition-duration: 0.5s;
  transition: visibility 0s, opacity 0.5s linear;
  position: absolute;
  /* color: white; */
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}
