.about-video-container iframe {
  aspect-ratio: 720/425;
  width: 100%;
}

.about-us-power-to-choose {
  /* background-image: url("https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/background/12.png"); */
  background-size: 150% auto;
  background-position: center;
}

.about-us-leadership-team {
  /* background-image: url("https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/background/leadership_2.jpg"); */
  background-size: cover;
  background-position: center;
}
.media-coverage {
  background: linear-gradient(
    to bottom,
    rgba(207, 233, 207, 0),
    rgba(207, 233, 207, 0.1),
    rgba(207, 233, 207, 0.9)
  );
}
.view-more {
  margin-top: -86px;
  padding-bottom: 86px;
}

h1 {
  font-size: 65px;
  margin-bottom: 0;
}

.media-coverage-content {
  height: 27%;
}
@media only screen and (max-width: 776px) {
  .abt-pow-title {
    font-size: 50px;
  }
  .carrousel-parent div:first-child {
    width: 100% !important;
  }
}
@media only screen and (max-width: 576px) {
  .abt-pow-title {
    font-size: 24px;
  }
  .css-1fzpoyk {
    width: 80%;
  }
  .media-cov-contain {
    width: 86%;
  }
  .media-coverage {
    height: 29rem !important;
  }
}
