.accordion-button:not(.collapsed){
    background-color:#c2df9f !important;
    font-size: 18px;
  }
  .accordion-button:not(.collapsed){
    color: black;
  }
  
  .accordion-button:focus{
    border: none;
    box-shadow: none;
  }
  .accordion-button::after {
    background-image: url("https://cdn-icons-png.flaticon.com/512/5611/5611992.png");
  }
  .accordion-button:not(.collapsed)::after{
    background-image: url("https://cdn-icons-png.flaticon.com/512/5611/5611992.png");
  }
  .accord-font{
    font-size: 18px;
  }
  .accord-custom {
    width: 75%;
  }  