.description-container {
  display: flex;
  align-content: space-around;
}

.title {
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.description-container .desc .h5 {
  text-align: justify;
  white-space: pre-line;
}
.mobile-only {
  display: none;
}

.description-container .desc .h5 .toggle-button {
  color: #00aa13;
  font-weight: bold;
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }
}
