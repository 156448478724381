@media only screen and (max-width: 998px) {
  .modal-title {
    font-size: 25px;
  }
}
@media only screen and (max-width: 776px) {
  .md-font {
    font-size: 12px;
  }
}
