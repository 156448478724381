* {
  box-sizing: border-box;
}
body {
  font-family: Montserrat, sans-serif;
  font-weight: 500 !important;
}
/* utility classes  */
.gt-color {
  color: #00aa13;
}
.gt-font {
  font-size: 18px;
}
.gt-bg {
  background-color: #00aa13;
}
.nav-color {
  color: rgb(165, 176, 160);
}
.theme-dark {
  background: #848484;
}
.theme-light {
  background: #cfe9cf;
}
.box-modal::-webkit-scrollbar {
  width: 8px;
}
.box-modal::-webkit-scrollbar-thumb {
  background: #00aa13;
  border-radius: 5px;
}
.gt-head {
  right: -35%;
  top: 25%;
}
.css-1fzpoyk {
  top: 55.5% !important;
}
/* .intro-left-part p::after{
  display: none;
} */
.carousel-control-next-icon {
  background-image: url("https://cdn-icons-png.flaticon.com/128/81/81068.png");
}
.carousel-control-prev-icon {
  background-image: url("https://cdn-icons-png.flaticon.com/128/81/81037.png");
}
.css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  font-size: 1.25rem;
}

@media only screen and (max-width: 576px) {
  .box-modal {
    width: 90% !important;
  }
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.opt-1:hover {
  background-color: darkgray;
}
.opt-2:hover {
  background-color: darkgray;
}
.modal-container::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: #00aa13;
}
.outline_input:focus {
  border-color: blue !important;
}
