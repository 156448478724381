.blog-video {
  width: 75%;
}
@media screen and (max-width: 998px) {
  .blog-content {
    width: 100% !important;
  }
  .blog-lists {
    width: 100% !important;
  }
  .blog-container {
    margin-top: 8rem !important;
  }
}
@media screen and (max-width: 576px) {
  .blog-video {
    width: 100%;
  }
  .blog-desc {
    width: 80% !important;
  }
  .thumb-nail {
    width: 80% !important;
  }
}
