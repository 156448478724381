.horz-container {
  width: 100%;
  height: calc(100vh - 90px - 3rem);
  background-color: rgb(241, 241, 239);
}

h1 {
  font-size: 65px;
}
.scroll-card {
  width: 24%;
  height: 350px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 18px;
  background: #cfe9cf !important;
  box-shadow: 0 0 4px 0 #d9d9d9;
  transition: all 0.4s ease-in-out;
  padding: 0px 4px;
  padding-bottom: 10px;
}
.scroll-card img {
  width: 350px;
  height: 250px;
  object-fit: cover;
  object-position: center;
}
.scroll-card:hover {
  transform: scale(1.1, 1.2);
}
.vech-convert-font {
  font-size: 30px;
}
.vech-convert-details {
  font-size: 18px;
}

@media only screen and (max-width: 998px) {
  .horz-container {
    height: auto !important;
    gap: 7rem;
    padding: 7rem 0rem !important ;
  }
  .scroll-body {
    gap: 6rem !important;
  }
  .hor-title {
    font-size: 50px !important;
  }
  .scroll-card {
    width: 50%;
  }
  .horz-container > h2 {
    font-size: 26px;
  }
  .scroll-card:hover {
    transform: scale(1.1, 1.2);
  }
}
@media only screen and (max-width: 576px) {
  .hor-title {
    font-size: 39px !important;
  }
  .scroll-card {
    width: 90%;
  }
  .scroll-card img {
    width: 90% !important;
  }
}
