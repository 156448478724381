.circular-card-style {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgb(118, 141, 115);
  opacity: 0.85;
}

