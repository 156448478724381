.grass-container {
  height: calc(100vh - 90px);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.grass-container .grass-card {
  width: calc(100% / 5.25);
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: calc(calc(100% / 5) - calc(100% / 5.25));
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.grass-container .grass-card:hover {
  transition: all 0.5s ease-in-out;
  border-radius: 15px;
  box-shadow: 0px 0px 8px 1px #e1e1e1;
}

.grass-container .grass-card.card-even,
.grass-container .grass-card.card-odd {
  /*  */
  background-image: url("https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/grass/grass_bg_bottom.svg");
  background-position: center;
  background-size: cover;
}

.grass-container .grass-card .header,
.grass-container .grass-card .title,
.grass-container .grass-card .desc {
  margin: 0;
}
.grass-container .grass-card .header {
  font-size: 7rem;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00aa13;
  font-family: monospace;
  text-shadow: -5px -5px 5px #e1e1e1;
}

.grass-container .grass-card .title {
  font-size: 2.5rem;
  text-transform: uppercase;
  color: #00aa13;
  font-family: "Courier New", Courier, monospace;
}

.grass-container .grass-card .desc-container {
  text-align: justify;
  display: flex;
  align-items: flex-start;
  height: 55%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grass-container .grass-card .desc {
  text-align: justify;
  white-space: pre-line;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media only screen and (max-width: 992px) {
  .circular-div {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 75%;
    width: 110%;
    top: 25%;
    right: -5%;
  }
  .grass-container {
    height: auto;
  }
  .circular-div {
    height: 60%;
    top: 40%;
  }
  .circular-card-1 {
    top: 39% !important;
    left: 1.5%;
  }
  .circular-card-2 {
    left: 19.5%;
    top: 32%;
  }
  .circular-card-3 {
    top: 30%;
    right: 42.5%;
  }

  .circular-card-4 {
    right: 20.5%;
    bottom: 51%;
  }

  .circular-card-5 {
    right: 1.5%;
    top: 39%;
  }
  .para-container {
    right: 39%;
    top: 58%;
  }
  .grass-container {
    top: 10%;
    left: 16%;
  }
}
@media only screen and (max-width: 734px) {
  .circular-card {
    width: 85px;
    height: 85px;
  }
  .circular-div {
    height: 44%;
    top: 56%;
  }
  span.grass-letter {
    font-size: 4rem;
  }
  .para-container {
    right: 33%;
    top: 66%;
  }
  .circular-para {
    width: 33vw;
  }
  .circular-card-1 {
    top: 57% !important;
  }
  .circular-card-3 {
    top: 51%;
  }
  .circular-card-2 {
    top: 52%;
    /* right: 44.5%; */
  }
  .circular-card-4 {
    bottom: 37%;
  }
  .circular-card-5 {
    top: 58%;
  }
  .grass-container {
    top: 24%;
  }
}
@media only screen and (max-width: 734px) {
  .circular-card {
    width: 55px;
    height: 55px;
  }
  .circular-card-2 {
    top: 53%;
    left: 20.5%;
  }
  .circular-card-4 {
    bottom: 39%;
    right: 21.5%;
  }
  .circular-para {
    width: 70vw;
  }
  .para-container {
    right: 13%;
    top: 68%;
  }
}

/* @media only screen and (max-width: 768px) {
  .grass-container {
    flex-direction: column;
  }

  .grass-container .grass-card {
    width: calc(100% - 30px);
    height: calc(calc(100vh - 110px) / 6);
    flex-direction: revert;
    border-radius: 15px;
    box-shadow: 0px 0px 8px 1px #e1e1e1;
    margin: 0 10px;
  }

  .grass-container .grass-card .header {
    padding: 10px;
  }

  .grass-container .grass-card .title {
    position: absolute;
    filter: blur(1px);
    left: 14px;
    color: #8484844f;
    font-size: 3rem;
    width: 100%;
    height: 15%;
  }

  .grass-container .grass-card .desc-container {
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 10px;
    font-size: 1rem;
    height: 100%;
  }
}
@media only screen and (max-width: 576px) {
} */
