.test-container{
    width:100%;
    height:100%;
    
}
.test-image{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.test-text-container{
    width:70%;
}
