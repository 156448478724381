.item-container {
  flex-direction: column;
  align-items: center;
  width: 68%;
  /* aspect-ratio: 1; */
  border: thin solid #00aa13;
  border-radius: 10px;
  /* box-shadow: 0 0 2px 0 #00aa13; */
  flex-shrink: 0;
  height: 12rem;
}

.text-container {
  width: 100%;
  background-color: #cfe9cf;
  border-radius: 0 0 10px 10px;
  color: black;
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.carousel-text {
  font-size: 18px;
}
.carousel-desc {
  font-size: 12px;
}

.multi-carousel-img {
  width: 100%;
  aspect-ratio: 1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-grow: 1;
  border-radius: 10px 10px 0px 0px;
}

.react-multi-carousel-item {
  width: 100px;
}
.carousel-button-group {
  display: flex;
  justify-content: center;
}

.carousel-button-group svg {
  color: #00aa13;
}
.react-multi-carousel-track {
  gap: 4px;
}

.carousel-button-group .icon-back {
  height: 25px;
  aspect-ratio: 1;
  background-image: url("https://cdn-icons-png.flaticon.com/128/81/81037.png");
  background-size: contain;
}
.carousel-button-group .icon-forward {
  height: 25px;
  aspect-ratio: 1;
  background-image: url("https://cdn-icons-png.flaticon.com/128/81/81068.png");
  background-size: contain;
}

.carousel-button-group .icon-back,
.carousel-button-group .icon-forward {
  margin: auto;
}
.media-cov-contain {
  height: 25rem !important;
  width: 80% !important;
}
.media-cov-contain div {
  background-size: cover !important;
}
/* .carousel-button-group .icon-container {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  background-color: #e1e1e1;
  border-radius: 50%;
  cursor: pointer;
} */
@media only screen and (max-width: 992px) {
  .item-container {
    width: 85%;
  }
}
@media only screen and (max-width: 776px) {
  .item-container {
    width: 85%;
    height: 90%;
  }
}
@media only screen and (max-width: 576px) {
  .carousel-text {
    font-size: 16px;
  }
  .carousel-desc {
    font-size: 10px;
  }
  .media-cov-contain {
    height: 15rem !important;
  }
}
