.drawer-container {
  position: absolute;
  width: 211px;
  background-color: white;
  top: 0;
  right: 0;
  box-shadow: 0px 0px 20px #e1e1e1;

  border-radius: 5px;
  z-index: 1100;
}
.drawer-container > i {
  cursor: pointer;
}
.drawer-products a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.drawer-about-us a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.drawer-locate a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.drawer-locate {
  display: none !important;
}

.drawer-about-us {
  border-bottom: thin solid #00aa13;
  padding: 8px;
}
.drawer-products {
  border-bottom: thin solid #00aa13;
  padding: 8px;
}

@media only screen and (max-width: 998px) {
  .drawer-locate {
    display: flex !important;
  }
}
