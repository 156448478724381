.new-page-intro-container {
  /* margin-top: 75px; */
  height: calc(100vh - 90px - 3rem);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("https://images.pexels.com/photos/7846477/pexels-photo-7846477.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"); */
}
.intro-section {
  padding: 0rem 38px;
}
.special-class {
  margin-bottom: 4rem;
}
.type-anime {
  /* top: 60%;
  left: 12%; */
  height: 150px;
}
.type-anime p {
  text-align: center;
}
.intro-left-part {
  width: 53%;
  height: auto;
  /* margin: 0 auto; */
}
.intro-left-part {
  text-align: center;
  /* font-size: 43px; */
}
.intro-left-part p {
  /* margin-top: 1.5rem; */
  font-size: 30px !important ;
}
.intro-right-part {
  width: 46%;
  aspect-ratio: 16/9;
}
.intro-right-part > video {
  width: 100%;
  height: 100%;
}
.intro-card-container {
  width: 75%;
}
.footer {
  height: 24%;
  /* background-color: #d7d2d2; */
  /* background-color: #cfe9cf; */
  background: transparent;
}
.heavy-industries {
  width: 100%;
  height: 30%;
  background-image: url("https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/iot_icons/IOT_01.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.worlds-first-image {
  background: url("https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/grass_icons/worlds-first-icon.png");
  height: 148px;
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 1;
}
.vehicle-conv-type {
  height: 100px;
}
.first-sec-type {
  left: 9%;
}

.foot-title.image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  text-transform: uppercase;
}
/* .intro-foot-box-2 img {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.intro-foot-box-3 img {
  object-fit: cover;
  object-position: center;
}
.intro-foot-box-4 img {
  object-fit: cover;
  object-position: center;
}
.intro-foot-box-5 img {
  object-fit: cover;
  object-position: center;
} */
.footer {
  height: 24%;
  opacity: 0.9;
}
.intro-footer {
  height: 27%;
}
/* .heavy-industries{
  width:100%;
  height:45%;
  background-image: url("https://greentiger.in/assets/partners/4.png");
  background-position:center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 2px solid black;

} */
/* .intro-foot-box-1 img:first-child{
  object-fit: cover;
  object-position: center;
  border:2px solid red;


} */
/* .small-img{
  width:88%;
  height: 24%;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}
.intro-foot-box-2 img{
  width:40%;
  height:40%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center; 
}
.intro-foot-box-3 img{
width: 45%;
height:35%;
object-fit: cover;
  object-position: center; 
}
.intro-foot-box-4 img{
  width: 45%;
height: 40%;
object-fit: cover;
  object-position: center; 

}
.intro-foot-box-5 img{
  width: 45%;
  height: 40%;
  object-fit: cover;
  object-position: center; 

} */

.intro-foot-item {
  font-size: 18px;
}
/* .intro-foot-items {
  width: 10rem;
  height: 12rem;
} */
.intro-foot-item img {
  width: 100%;
  height: 40%;
  object-fit: contain;
  object-position: center;
}
.intro-para-first-word {
  font-size: 65px;
}
.intro-foot-items img {
  width: 100%;
  height: 6rem;
  object-fit: contain;
  object-position: center;
}
.intro-foot-items span {
  width: 100%;
  height: 2rem;
}
.intro-foot-item span {
  width: 100%;
  height: 40%;
  border: 2px solid red;
}
.special-icon {
  width: 100% !important;
  height: 12rem !important;
  object-fit: cover !important;
}
.made-in-india {
  width: 12rem !important;
  height: 12 rem !important;
}
.made-in-india img {
  width: 15rem !important;
  height: 10rem !important;
  object-fit: cover !important;
}
.marquee {
  --gap: 1rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  height: 100%;
}
.marquee__content {
  flex-shrink: 0;
  display: flex;
  gap: var(--gap);
  justify-content: space-between;
  min-width: 100%;
  height: 100%;
}
.animation {
  overflow: hidden;
  height: 100%;
}

.animation .marquee__content {
  animation: scroll 35s linear infinite;
}
.gt-app-width {
  width: 55%;
  aspect-ratio: 16/9;
}
.gt-app-width video {
  width: 100%;
  height: 100%;
}

.convert-vechile-section {
  width: 100%;
  height: 100%;
}
.vechile-child {
  width: 100%;
  height: 100%;
}
.vechile-content {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(80px, 200px));

  align-items: center;
  justify-content: space-around;
}
.imag-animation {
  animation: anim 2s ease-in-out;
  animation-fill-mode: forwards;
}
.btn-shad {
  width: 250px;
  box-shadow: 0 0 6px 2px #00aa1378;
}
.vehicle-convert-intro {
  height: 90%;
}
.vehicle-header {
  height: 75%;
}
.vehicle-we-conv {
  position: absolute;
  top: -15%;
  left: 165%;
  width: 329px;
}
.vehicle-conv-logo {
  position: absolute;
  top: 49%;
  left: 10%;
}
.vehicle-btn-container {
  margin-bottom: 3rem;
}
.btn-contain {
  width: 50%;
}

@keyframes anim {
  from {
    opacity: 0;
    transform: scale(1);
  }
  to {
    opacity: 1;
    transform: scale(1.4);
  }
}
@media only screen and (max-width: 1208px) {
  .btn-shad {
    font-size: 1rem !important;
    padding: 4px !important;
  }
}

@media only screen and (max-width: 992px) {
  .btn-contain {
    width: 90%;
  }

  .vehicle-convert-intro {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .vehicle-btn-container {
    align-items: center;
    flex-direction: column;
    gap: 1.1rem;
    margin-bottom: 0rem;
    padding-bottom: 2rem;
  }
  .vehicle-header {
    height: 100% !important;
  }
  .vehicle-conv-logo {
    top: 15%;
    left: 32%;
  }
  .vehicle-conv-logo img {
    width: 50px !important;
  }
  .vehicle-right-part {
    height: 53% !important;
  }
  .vehicle-we-conv {
    top: 5%;
    left: 161%;
  }
  /* .type-anime img {
    width: 60px;
  } */
  .intro-left-part {
    width: 95%;
    height: 28% !important;
  }
  .intro-right-part {
    width: 46%;
    /* height: 92%; */
  }

  .intro-para-first-word {
    font-size: 50px;
  }
  .intro-left-part p {
    font-size: 30px !important;
  }
  .type-anime {
    height: 73px;
  }
  .special-icon {
    height: 9rem !important;
    object-fit: contain !important;
  }
  /* .made-in-india img {
    width: 7rem !important;
    height: 7rem !important;
  } */
}
@media only screen and (max-width: 776px) {
  .vehicle-convert-intro {
    height: 95% !important;
  }
  .vehicle-we-conv p {
    font-size: 22px;
  }
  .vehicle-we-conv {
    width: 250px;
  }
  .intro-right-part {
    height: 50%;
  }
  .intro-para-first-word {
    font-size: 36px;
  }
  .intro-left-part p {
    font-size: 24px !important;
  }
  .vehicle-conv-type {
    height: 100%;
  }
  .type-anime {
    height: 30px;
  }
  .intro-left-part {
    width: 100%;
    height: 20% !important;
    justify-content: space-between;
  }
  .intro-section {
    padding: 0 !important;
    height: 68%;
  }
  .intro-header {
    height: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .btn-contain {
    width: 100%;
  }
  .intro-foot-items img {
    display: none;
    opacity: 0.9;
  }
  .landing-active-logo {
    display: block !important;
    transform: scale(1.1);
    opacity: 1;
    transition: all 1s ease-in-out;
    position: absolute;
    left: 2%;
  }

  .intro-foot-items img {
    width: 100% !important;
  }

  .made-in-india {
    width: 100% !important;
    object-fit: contain;
    object-position: center;
  }
  .special-class {
    width: 100% !important;
  }
  .vehicle-we-conv {
    width: 150px !important;
    top: 14% !important;
    left: 167% !important;
  }
  .vehicle-conv-logo img {
    width: 30px !important;
  }
  .intro-para-first-word {
    font-size: 30px;
  }
  .intro-left-part {
    height: 24% !important;
  }

  .intro-left-part p {
    font-size: 18px !important;
  }

  .intro-right-part {
    width: 95%;
    height: 52%;
  }
}
@media only screen and (max-width: 440px) {
  .intro-right-part {
    width: 80% !important;
  }
  .vehicle-header {
    height: 100% !important;
  }
  /* .intro-right-part {
    height: 38%;
  } */
  .intro-left-part {
    height: 32% !important;
  }
  .vehicle-conv-logo {
    top: 12%;
    left: 28%;
  }
  .new-page-intro-container {
    height: calc(100vh - 160px);
  }
  .vehicle-convo {
    height: calc(100vh - 90px) !important;
  }
  .intro-left-part p {
    font-size: 16px !important;
  }
}
