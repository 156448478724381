#section-7 {
  width: 100%;
  /* background-color: #326274; */
  background-color: white;
  background-color: #06a909;
  
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  justify-content: space-around;
  color: #fff;
}

.sec-8-col {
  color: #295f73;
}
#section-7 div span {
  /* color: #fff; */
  color: white;
  font-size: 18px;
}
.green-tiger-logo {
  background-image: url("https://s3.ap-south-1.amazonaws.com/greentiger.in-content/website_3_0/logo.png");
  width: 200px;
  height: 65px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.foot-color {
  color: #fff;
}
.footer-subscribe form{
  width: 100%;
}
.input-footer-field{
  width: 100%;
  flex-grow: 1;
}
.input-footer-field input{
  outline: none;
  width: 100%;
  font-size: 18px;
  flex-grow: 1;

}
.footer-subscribe button{
  border: none;
  font-size: 0.8rem;
  background-color: #022836;
  /* background-color:#00aa13; */
  color: white;
  font-size: 18px;
  padding: 0 1.5rem;
}
.foot-contact-btn{
  background-color: #022836;
  /* background-color: #00aa13; */
  /* padding: 0.3px; */
  width: 120px;
  /* box-shadow: 0 0 6px 1px white; */
  cursor: pointer;
}
.social-icon-container .social-icon {
  height: 25px;
  aspect-ratio: 1;
  background-size: contain;
  cursor: pointer;
}
@media only screen and (max-width: 1065px) {
  #section-7 {
    grid-template-columns: repeat(2, minmax(70px, 400px));
    grid-gap: 12%;
  }
}
@media only screen and (max-width: 768px) {
  #section-7 {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
    grid-gap: 25px;
  }
  #section8 {
    padding: 12px;
  }
}
@media only screen and (max-width: 576px) {
  .green-tiger-logo {
    height: 28px;
    
  }
  .sec-8-col{
    font-size: 12px;

  }
  .social-icon-container .social-icon{
    height: 15px;
  }
  #section8 {
    padding: 6px;
  }
}


